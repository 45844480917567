/* Homepage.css */
/************** HOMEPAGE CONTAINER ****************/

.homepage-container,
#root {
  width: 100%;
  height: 100%;
  /*flex-wrap: wrap;*/
}
/************** FEATURED-SECTION ****************/
.featured-section {
  width: 100%;
  background-color: #f1debd;
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  padding-top: 30px;
  height: 150px;
  position: fixed;
}

.featured-section h2 {
  color: #547d9d;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  width: 80%;
}
.icone-main-verre {
  height: 110px;
  width: 25%;
  color: #fff;
  background-image: url(../src/8.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  top: 10px;
  right: 10px;
}
.featured-section p {
  color: #666;
}
/************** CREATE SECTION ****************/

.create-section {
  background-color: #e8e8e8;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}
.create-section ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 200px;
}
.page-item {
  width: 74%;
  height: 40px;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px 10px 0px 10px;
  color: #666;
}

.page-item a {
  color: #666;
  text-decoration: none;
  padding-left: 20px;
  font-family: "Montserrat";
  font-weight: 250;
}

.page-item button {
  height: 40px;
  width: 50px;
  color: white;
  border: none;
  border-radius: 0px 50px 50px 0px;
  cursor: pointer;
  margin: 0px;
  background-color: #dac6b6;
  font-weight: bolder;
}

.page-item button:hover {
  background-color: #800020;
}
/************** FOOTER ****************/
.footer-homePage {
  background-color: #f1debd;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 250px;
  border-radius: 15px 15px 0 0;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: -120px;
}
.button-group {
  width: 80%;
  display: flex;
  justify-content: space-around;
  z-index: 2;
  position: relative;
  bottom: 40px;
}
.btn-rect {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #666;
  padding: 0px 7px 0px 7px;
  position: relative;
  z-index: 50;
  height: 80px;
}
.btn-rect p {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 100;
}
.btn-ajouter,
.btn-cloturer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
}

/* Fond semi-transparent derrière le modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Fenêtre du modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
}

/* Style des boutons */
.modal button {
  margin: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

/* Bouton de confirmation */
.modal .confirm {
  background: #547d9d;
  color: white;
}

/* Bouton d'annulation */
.modal .cancel {
  background: #666;
  color: white;
}
/*******************LOADING******************/

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***********************************************************/
/*

.add-fiche-container {
  background-color: #800020;
  color: white;
  padding: 20px;
  text-align: center;
  cursor: pointer;

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card h3 {
  margin-bottom: 10px;
  color: #333;
}

.card p {
  color: #666;
}

.navigate-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  background-color: #dac6b6;
  padding: 10px;
}
*/
