@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");

/* Styles pour le conteneur principal */
.wine-rating-container {
  width: 100%;
  max-width: 28rem; /* equivalent à max-w-md */
  margin-left: auto;
  padding: 1rem;
}

/* Espacement entre les éléments */
.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

/* Styles pour les labels et valeurs */
.rating-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
}

.rating-label {
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
}

.rating-value {
  font-size: 0.875rem;
  width: 3rem; /* w-12 */
  text-align: right;
}

/* Styles pour le slider */
.rating-slider {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.5rem;
  appearance: none;
  background: #e5e7eb;
  cursor: pointer;
  position: relative;
}

/* Ajout du remplissage en #666 */
.rating-slider::-webkit-slider-runnable-track {
  background: #666;
}

.rating-slider::-moz-range-progress {
  background: hsl(0, 0%, 60%);
  font-size: 30px;
}

/* Styles pour le thumb du slider */
.rating-slider::-webkit-slider-thumb {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #666;
  border: none;
  cursor: pointer;
  margin-top: -0.25rem;
  z-index: 3;
}

.rating-slider::-moz-range-thumb {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: #666;
  border: none;
  cursor: pointer;
  z-index: 3;
}

/* Style pour le marqueur central */

/* Styles pour la section d'évaluation */
.evaluation-section {
  padding-top: 1rem;
  border-top: 2px solid #666; /* Ajout de la barre de séparation */
}

/* Mise en gras du label "Évaluation" */
.evaluation-label {
  font-weight: bold;
}

/* Styles pour désactiver le focus outline par défaut */
.rating-slider:focus {
  outline: none;
}

/* Style pour améliorer l’accessibilité lors du focus */
.rating-slider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 2px rgba(102, 102, 102, 0.2);
}

.rating-slider:focus::-moz-range-thumb {
  box-shadow: 0 0 0 2px rgba(102, 102, 102, 0.2);
}

/* Style pour l’état hover du slider */
.rating-slider:hover::-webkit-slider-thumb {
  background: #555;
}

.rating-slider:hover::-moz-range-thumb {
  background: #555;
}

/* Styles pour la section d'évaluation */
.evaluation-section {
  padding-top: 1rem;
  border-top: 2px solid #666;
}

/* Mise en gras du label "Évaluation" */
.evaluation-label {
  font-weight: bold;
}

/* Section Ma Notation Personnelle */
.personal-rating {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid #666;
  text-align: center;
}

.personal-rating-label {
  font-size: 0.875rem;
  font-weight: bold;
  color: #666;
  display: block;
  margin-bottom: 0.5rem;
}

/* Conteneur des étoiles */
.star-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Style des étoiles */
.star {
  color: #e5e7eb; /* Couleur par défaut */
  transition: color 0.2s ease-in-out;
  font-size: 40px;
}

.star.filled {
  color: #ffd700; /* Jaune quand sélectionné */
}

.star:hover {
  color: #ffd700;
}

.score {
  font-family: "Caveat", cursive;
  font-size: 2.5rem;
  color: #666;
  /* margin-right: 30px; */
  /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);*/
}
.evaluation-text {
  color: #666;
  text-align: end;
  font-family: "Montserrat";
}
