.root,
.total-login {
  width: 100%;
  height: 100%;
  z-index: -10;
}
/*Loading Animation */

.bg-logo {
  background-color: #dac6b6;
  background-attachment: fixed;
  background-position-x: center;
  background-repeat: no-repeat;
  background-image: url("/src/5.png");
}
.login-page {
  height: 60%;
  width: 100%;
  background-color: #dac6b6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
}

.login-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  position: relative;
  height: 50%;
  top: 240px;
}

.bloc-login {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 90%;
}
.login-form input[type="text"] {
  color: #574e4e; /* This is a burgundy color */
  border: 1px solid #ccc;
  border-radius: 0px 30px 30px 0px;
  box-sizing: border-box;
  background-color: #e9e9e9;
  border: none;
  height: 36px;
  padding-left: 5px;
  width: 250px;
  font-family: "Montserrat", sans-serif;
}

.icons-bg {
  background-color: #e9e9e9;
  border-radius: 30px 0px 0px 30px;
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icons-bg .icons {
  border: #574e4e solid 1px;
  border-radius: 50px;
  padding: 2px;
}
.icons {
  color: #574e4e !important;
  width: 20px;
  height: 20px;
}
/*
.bloc-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 15px;
}*/
.email-flex {
  display: flex;
  flex-direction: row;
}

.login-form button {
  width: 100%;
  background-color: #800020;
  color: #fbfafa;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 36px;
}
.login-form button:hover {
  background-color: #d2b093;
}
.txt-login {
  color: #817f7f;
  text-align: center;
  width: 250px;
  font-family: "Montserrat", sans-serif;
}

.footer-login {
  height: 50%;
  background-color: #800020 !important;
  position: relative;
  top: -20px;
  z-index: -1;
}
