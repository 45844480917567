/*Import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://example.com/wine-background.jpg') no-repeat center center/cover;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.access-code-container {
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.access-code-container h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #c79f70;
}

.access-code-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.access-code-container input {
  padding: 15px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 100%;
  max-width: 300px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.access-code-container input::placeholder {
  color: #fff;
  opacity: 0.7;
}

.access-code-container button {
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  background: #7d2f18;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.access-code-container button:hover {
  background: #a04426;
}

.access-code-container p {
  margin-top: 20px;
  color: #ff6347;
}
*/

.access-code-container {
  height: 100%;
  width: 100%;
  background-color: #f1debd;
  z-index: -1;
}
.access-code-image {
  height: 60%;
  background-image: url("../src/logo\ blanc.png");
  background-repeat: no-repeat;
  background-size: contain;
  object-position: center;
  background-color: #547d9d;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
}
.access-code-image h2 {
  color: #fff;
  position: relative;
  top: 310px;
  text-align: center;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 100;
}
.access-code-white-box {
  background-color: #fff;
  border-radius: 20px;
  margin: 10%;
  height: 300px;
  z-index: 2;
  position: relative;
  top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.access-code-white-box h1 {
  text-align: center;
  color: #547d9d;
  font-size: 30px;
  position: relative;
  top: 20px;
}
.access-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 150px;
  width: 100%;
}
.access-form input,
.access-form button {
  border-radius: 30px;
  width: 260px;
  height: 45px;
  font-size: 16px;
}
.access-form input {
  border: #f1debd 1px solid;
  text-align: center;
  background-color: #f1debd;
  color: #547d9d;
}
.access-form button {
  border: #547d9d 1px solid;
  background-color: #547d9d;
  color: #fff;
  font-weight: bold;
}
